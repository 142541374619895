import { observable, action, decorate } from 'mobx';
import signalr from 'SignalR/Connection'
import userStore from 'stores/userStore'
import agent from 'agent'
import { IDriver, IDriverMapMarker, IFullDriver } from 'ServicesInterfaces';

class MapStore {
    isLoadigDriverForFilter: boolean = true
    driversForFilter: IDriver[] = []
    driversSelectedOnFilter: IDriver[] | null = null
    driversMarkers: IDriverMapMarker[] = []

    constructor() {
        this.reset()
    }

    reset() {
        this.isLoadigDriverForFilter = true
        this.driversForFilter = []
        this.driversSelectedOnFilter = null
        this.driversMarkers = []

        signalr.disconnect()
    }

    init() {
        this.reset()
        // Preparar conexión
        signalr.setUpSignalRConnection(userStore.currentCompany!.KeyMembership, userStore.currentCompany!.KeyCompany)

        // Definir las suscripciones
        signalr.hubProxy.on("PaintPin", (positionMap: IDriverMapMarker) =>
            this.onPositionSignal(positionMap)
        )



        this.getDriversFromService().then(() => {
            //Iniciar la conexion de signal
            signalr.startConnection().then(() => {
                //acción de inicializacion
            })
        })
    }

    onPositionSignal(positionMap: IDriverMapMarker) {
        let driverMarkerIndex = this.driversMarkers.findIndex(x => x.Id === positionMap.Id)
        if (driverMarkerIndex > -1) {

            const prevLt = this.driversMarkers[driverMarkerIndex].Latitude
            const prevLg = this.driversMarkers[driverMarkerIndex].Longitude

            // En un futuro, es preferible que la rotación venga desde el servicio y que este a su vez la obtenga
            // de los sensores del dispositivo.
            const newRotation = this.invertBearing(this.bearing(prevLt, prevLg, positionMap.Latitude, positionMap.Longitude))

            this.driversMarkers[driverMarkerIndex] = {
                ...this.driversMarkers[driverMarkerIndex],
                Latitude: positionMap.Latitude,
                Longitude: positionMap.Longitude,
                Lunch: positionMap.Lunch,
                Rotation: newRotation
            }


        } else {
            this.driversMarkers = [...this.driversMarkers, positionMap]
        }

    }

    // Se invierte el valor de la rotación para que el icono apunte hacia la dirección del movimiento
    // y no a las coordenadas anteriores.
    invertBearing(bearing: number){
        return bearing + 180
    }

    // Esta función calcula la rotación con respecto al punto anterior
    // Si se usara este valor para rotar el icono, el icono apuntaría hacia las coordenadas anteriores
    bearing(startLat: number, startLng: number, destLat: number, destLng: number) {
        startLat = this.toRadians(startLat);
        startLng = this.toRadians(startLng);
        destLat = this.toRadians(destLat);
        destLng = this.toRadians(destLng);

        const y = Math.sin(destLng - startLng) * Math.cos(destLat);
        const x = Math.cos(startLat) * Math.sin(destLat) -
            Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        let brng = Math.atan2(y, x);
        brng = this.toDegrees(brng);
        return (brng + 360) % 360;
    }

    toRadians(degrees: number) {
        return degrees * Math.PI / 180;
    };

    // Converts from radians to degrees.
    toDegrees(radians: number) {
        return radians * 180 / Math.PI;
    }

    getDriversFromService() {
        return new Promise<void>((resolve) => {
            this.isLoadigDriverForFilter = true
            agent.Drivers.all().then(driversData => {
                let drivers: IDriver[] = driversData.map((driveData: IFullDriver) => driveData.Driver)
                this.driversForFilter = drivers
                this.driversMarkers = drivers.map(driver => {
                    return {
                        Id: driver.Id,
                        Latitude: driver.Latitude,
                        Longitude: driver.Longitude,
                        Lunch: driver.Lunch,
                        Name: driver.CompleteName
                    } as IDriverMapMarker
                })
                resolve()
            }).finally(() => this.isLoadigDriverForFilter = false)
        })
    }

    setDriversSelectedOnFilter(driversSelected: IDriver[] | null) {
        this.driversSelectedOnFilter = driversSelected
    }
}

decorate(MapStore, {
    isLoadigDriverForFilter: observable,
    driversForFilter: observable,
    driversSelectedOnFilter: observable,
    driversMarkers: observable,
    reset: action,
    onPositionSignal: action,
    getDriversFromService: action,
    setDriversSelectedOnFilter: action
})

export default new MapStore();